<template>
  <div style="display: flex; height: calc(100vh - 125px); overflow: auto">
    <Menu
      theme="light"
      :active-name="currentMenuName"
      style="width: 220px"
      @on-select="(name) => $emit('toggleMenu', name)"
    >
      <MenuItem name="baseInfo">基本信息</MenuItem>
      <MenuItem name="group">选择议事人群范围</MenuItem>
    </Menu>
    <div style="flex: 1; padding: 0 20px">
      <Form
        :disabled="startend == 'end' || buttonRoot != '1001'"
        :model="baseInfoForm"
        inline
        :label-width="110"
        v-show="currentMenuName == 'baseInfo'"
      >
        <FormItem style="width: calc(100% - 100px)">
          <span class="validate" slot="label">信息名称:</span>
          <Input
            v-model.trim="baseInfoForm.title"
            :maxlength="50"
            placeholder="请输入信息名称，不超过50字，20字以内最佳"
          ></Input>
        </FormItem>
        <FormItem style="width: calc(100% - 100px)">
          <span class="validate" slot="label">信息来源:</span>
          <Input
            v-model.trim="baseInfoForm.source"
            placeholder="请输入信息来源"
          ></Input>
        </FormItem>
        <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=详情信息（必填）"
          style="width: 100%; height: calc(100vh - 246px); border: 0px"
          @load="loadUeditor1"
        ></iframe> -->
        <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情信息（必填）"
                style="width: 100%; height: calc(100vh - 246px); border: 0px"
                   @load="loadUeditor1"
              ></iframe>
      </Form>
      <div style="flex: 1; padding: 0 20px" v-show="currentMenuName == 'group'">
        <Collapse value="1">
          <Panel name="1">
            {{ baseInfoForm.communityName }}
            <p slot="content">
              <span style="display: flex; justify-content: flex-start">
                <Button
                  type="primary"
                  v-if="startend == 'start' && buttonRoot == '1001'"
                  style="margin-right: 16px"
                  @click="allSelectGroup"
                  >全选</Button
                >
                <Button
                  type="primary"
                  v-if="startend == 'start' && buttonRoot == '1001'"
                  style="margin-right: 16px"
                  @click="deleteSelectGroup"
                  >清除选择</Button
                >
                <Button
                  type="primary"
                  style="margin-right: 16px"
                  @click="expandSelectGroup"
                  >全展开</Button
                >
                <Button
                  type="primary"
                  style="margin-right: 16px"
                  @click="shrinkSelectGroup"
                  >全收缩</Button
                >
              </span>
              <Tree
                :data="groupTreeData"
                empty-text="暂无群范围"
                @on-check-change="oneSelectGroupTree"
                show-checkbox
                multiple
              ></Tree>
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentMenuName", "businessId", "startend", "buttonRoot"],
  data() {
    return {
      baseInfoForm: {},
      groupTreeData: [], //群范围
      regionIds: [],
    };
  },
  methods: {
    saveBaseInfo() {
      if (!this.baseInfoForm.title) {
        this.$Message.error({
          background: true,
          content: "请输入信息名称",
        });
        return;
      }
      if (!this.baseInfoForm.source) {
        this.$Message.error({
          background: true,
          content: "请输入信息来源",
        });
        return;
      }
      var v_Obj = document.getElementById("ueditor").contentWindow;
      this.baseInfoForm.content = v_Obj.getContent();
      if (!this.baseInfoForm.content) {
        this.$Message.error({
          background: true,
          content: "请输入详情信息",
        });
        return;
      }
      if (this.regionIds.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择议事人群范围",
        });
        return;
      }
      this.$post("/sendmsg/pc/chat/discussBusiness/updateBaseInfo", {
        businessId: this.businessId,
        communityCode: this.baseInfoForm.communityCode,
        content: this.baseInfoForm.content,
        regionIds: this.regionIds.join(","),
        source: this.baseInfoForm.source,
        title: this.baseInfoForm.title,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "提交成功",
            });
            setTimeout(() => {
              this.$Message.destroy();
              this.$emit("saveSuccessBaseInfo");
            }, 500);
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "提交失败，请联系管理员处理",
          });
        });
    },
    // 获取群范围tree
    getGroupTree() {
      this.$get("/sendmsg/pc/chat/discussBusiness/getRegionTree", {
        businessId: this.businessId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.baseInfoForm.communityCode,
      }).then((res) => {
        if (res.code == 200 && res.dataList) {
          this.groupTreeData = res.dataList;
          this.echoGroupTree(this.groupTreeData);
        } else if (res.code == 200 && !res.dataList) {
          this.$Message.error({
            background: true,
            content: "未查询到您的群范围",
          });
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    // 回显群范围
    echoGroupTree(arr) {
      arr.map((item) => {
        if (this.startend == "end" || this.buttonRoot != "1001") {
          item.disabled = true;
        }
        if (
          (!item.children || item.children.length == 0) &&
          this.regionIds.indexOf(item.id) != -1
        ) {
          item.checked = true;
        } else if (item.children && item.children.length > 0) {
          this.echoGroupTree(item.children);
        }
      });
    },
    // 单次选中群范围
    oneSelectGroupTree(arr) {
      this.regionIds = [];
      this.selectGroupTree(arr);
    },
    // 全选，单次选中时，push到regionIds
    selectGroupTree(arr) {
      arr.map((item) => {
        if (!item.children || item.children.length == 0) {
          this.regionIds.push(item.id);
          this.regionIds = Array.from(new Set(this.regionIds));
        } else {
          this.selectGroupTree(item.children);
        }
      });
    },
    // 封装全选和反选群范围
    allDeleteSelectGroup(tree, checkVal, expandVal) {
      const result = [];
      tree.forEach((item) => {
        let id = item.id;
        let disabled = item.disabled !== undefined ? item.disabled : false;
        let parentId = item.parentId;
        let expand = expandVal !== "" ? expandVal : item.expand;
        let checked = checkVal !== "" ? checkVal : item.checked;
        let title = item.title;
        let code = item.code;
        let children = item.children;
        // 如果有子节点，递归
        if (children && children.length != 0) {
          children = this.allDeleteSelectGroup(children, checkVal, expandVal);
        }
        result.push({
          expand,
          code,
          checked,
          title,
          children,
          id,
          parentId,
          disabled,
        });
      });
      return result;
    },
    // 全选群范围
    allSelectGroup() {
      this.regionIds = [];
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        true,
        ""
      );
      this.selectGroupTree(this.groupTreeData);
    },
    // 反选群范围
    deleteSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        false,
        ""
      );
      this.regionIds = [];
    },
    expandSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        "",
        true
      );
    },
    shrinkSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        "",
        false
      );
    },
    loadUeditor1() {
      if (
        this.baseInfoForm.content != "" &&
        this.baseInfoForm.content != null &&
        this.baseInfoForm.content != undefined
      ) {
        var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
        v_Obj.setContent(this.baseInfoForm.content); //写入编辑器富文本内容
        if (this.startend == "end" || this.buttonRoot != "1001") {
          v_Obj.setPreview(true);
        }
      } else {
        setTimeout(this.loadUeditor1, 300);
      }
    },
    changeBaseInfo() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$get("/sendmsg/pc/chat/discussBusiness/queryBaseInfoByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.baseInfoForm = res.data;
            this.regionIds = [];
            this.baseInfoForm.regions.map((item) => {
              this.regionIds.push(item.regionId);
            });
            this.$Message.destroy();
            this.baseInfoStatus = true;
            this.getGroupTree();
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    this.changeBaseInfo();
  },
};
</script>
    
<style scoped lang='less'>
</style>